<template>
  <div>
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full">
          <picture v-if="blog.thumbnail">
            <source
              type="image/webp"
              :srcset="
                blog.thumbnail + $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="blog.thumbnail"
              :alt="'「' + blog.title + '」' + $store.state.publicLang.news.alt"
              class="w-100 m-0"
            />
          </picture>
          <h1 class="iv2-blog-detail__title">{{ blog.title }}</h1>
          <div class="iv2-blog-detail__subtitle">
            <span v-if="blog.opened_at" class="iv2-blog-detail__date">
              {{ blog.opened_at }}
            </span>
            <span
              v-if="blog.category_name"
              :class="categoryType(blog.category_name)"
              class="iv2-blog-detail__label"
            >
              {{ blog.category_name }}
            </span>
          </div>
          <div class="d-lg-flex justify-content-end">
            <div
              v-if="useShare == 1"
              class="d-md-flex align-items-center mr-md-auto mb-md-4 mb-lg-0"
            >
              <span class="d-block d-md-inline-block mr-4">{{
                $store.state.publicLang.blog.sns_share
              }}</span>
              <TwitterShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
              <FacebookShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
              <LineShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
              <PinterestShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
              <MailShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
              <UrlShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
            </div>

            <div class="d-flex">
              <div
                v-if="blog.pageview > 0"
                class="iv2-blog-detail__pageview theme__sub-bg mr-3 mr-md-4"
              >
                <span class="size-21 mr-2 font-weight-bold">{{
                  blog.pageview
                }}</span>
                <span class="size-14">{{
                  $store.state.publicLang.blog.pageview
                }}</span>
              </div>

              <!-- いいねボタン -->
              <Favorite :items="blog" @update="updateDetail()" />
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>

    <div class="container-fluid" v-html="blog.content"></div>

    <div class="container-fluid">
      <div v-if="blog.tag.length > 0" class="row clearfix">
        <div class="column full">
          <div class="spacer height-80"></div>
          <div class="iv2-blog-detail__connection theme__sub-bg">
            <div class="text-center theme__heading mb-4">
              <h3 class="theme__heading-text">
                {{ $store.state.publicLang.blog.connection_tag }}
              </h3>
            </div>
            <router-link
              v-for="tag in blog.tag"
              :key="tag.id"
              class="iv2-blog-detail__connection--tag"
              :to="{ name: 'blog', query: { tag_id: tag.id } }"
              ># {{ tag.name }}</router-link
            >
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div v-if="useShare == 1" class="column full">
          <div class="spacer height-80"></div>
          <div class="row">
            <div class="col-md-6 col-lg-4 mb-4">
              <TwitterShare :type="'box'" :href="url" />
            </div>
            <div class="col-md-6 col-lg-4 mb-4">
              <FacebookShare :type="'box'" :href="url" />
            </div>
            <div class="col-md-6 col-lg-4 mb-4">
              <LineShare :type="'box'" :href="url" />
            </div>
            <div class="col-md-6 col-lg-4 mb-4">
              <PinterestShare :type="'box'" :href="url" />
            </div>
            <div class="col-md-6 col-lg-4 mb-4">
              <MailShare :type="'box'" :href="url" />
            </div>
            <div class="col-md-6 col-lg-4 mb-4">
              <UrlShare :type="'box'" :href="url" />
            </div>
          </div>
        </div>
        <div class="column full">
          <div class="spacer height-80"></div>
          <div class="text-center">
            <span>
              <button
                @click="prevBlog"
                class="iv2-blog-detail__btn theme__main-btn"
              >
                {{ $store.state.publicLang.news.list_button }}
              </button>
            </span>
          </div>
          <div class="spacer height-100"></div>
        </div>
      </div>
    </div>

    <!-- 関連記事 -->
    <LayoutConnection :id="blog.id" :tags="tagList()" />

    <!-- ピックアップ記事 -->
    <BasePickup :limit="limit" class="theme__sub-bg" />
  </div>
</template>

<script>
import FacebookShare from "@/components/items/BaseFacebookShare.vue";
import TwitterShare from "@/components/items/BaseTwitterShare.vue";
import LineShare from "@/components/items/BaseLineShare.vue";
import PinterestShare from "@/components/items/BasePinterestShare.vue";
import MailShare from "@/components/items/BaseMailShare.vue";
import UrlShare from "@/components/items/BaseUrlShare.vue";
import LayoutConnection from "./LayoutConnection.vue";
import BasePickup from "@/components/items/BasePickup.vue";
import Favorite from "@/components/items/BaseFavorite.vue";

export default {
  components: {
    FacebookShare,
    TwitterShare,
    LineShare,
    PinterestShare,
    MailShare,
    UrlShare,
    LayoutConnection,
    BasePickup,
    Favorite,
  },

  props: {
    blog: Object,
    useShare: Number,
    categoryId: String,
    page: String,
  },

  data() {
    return {
      url: "",
      limit: 6,
    };
  },

  created() {
    this.url = location.href;
  },

  methods: {
    prevBlog() {
      this.$router.push({
        name: "blog",
        query: Object.assign({}, this.$route.query),
      });
    },

    tagList() {
      var tag = [];
      this.blog.tag.forEach((v) => {
        tag.push(v.id);
      });
      return tag;
    },

    updateDetail() {
      this.$emit("update");
    },

    // customize
    categoryType(name) {
      if (name == "インタビュー") {
        return "red-label";
      } else if (name == "就活コラム") {
        return "blue-label";
      } else if (name == "デジタルブック") {
        return "orange-label";
      } else if (name == "イベント情報") {
        return "green-label";
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-blog-detail__title {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 30px 0 0;
}

.iv2-blog-detail__subtitle {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;

  .iv2-blog-detail__date {
    font-weight: 600;
    margin-right: 20px;
    color: #777777;
  }

  .iv2-blog-detail__label {
    text-align: center;
    border: 1px solid;
    border-radius: 30px;
    padding: 1px 30px;
    font-weight: 600;
    font-size: 14px;
    border-color: #0d5898;
    color: #0d5898;

    &.red-label {
      border-color: #de0000;
      color: #de0000;
    }

    &.blue-label {
      border-color: #0d9bd0;
      color: #0d9bd0;
    }

    &.orange-label {
      border-color: #de8000;
      color: #de8000;
    }

    &.green-label {
      border-color: #158600;
      color: #158600;
    }
  }
}

.iv2-blog-detail__btn {
  max-width: 100%;
  padding: 10px 80px;
}

.iv2-blog-detail__pageview {
  padding: 6px 25px;
  border-radius: 10px;
  display: inline-block;
}

.iv2-blog-detail__connection {
  padding: 40px 30px;
  border-radius: 15px;
  text-align: center;

  .iv2-blog-detail__connection--tag {
    padding: 0px 20px;
    font-size: 14px;
    border: 1px solid #acacac;
    border-radius: 15px;
    margin: 5px;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-blog-detail__title {
    font-size: 1.5rem;
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-blog-detail__title {
    font-size: 1.3rem;
  }
}
</style>
