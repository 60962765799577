<template>
  <nav :class="{ 'container-fluid': routeCheck() }">
    <div :class="{ row: routeCheck() }">
      <div :class="{ 'column full': routeCheck() }">
        <ol class="breadcrumb m-0 py-4">
          <li class="breadcrumb-item">
            <router-link :to="'/' + lang + query">
              {{ $store.state.themeLang.general.breadcrumb_top }}
            </router-link>
          </li>
          <li
            v-for="item in items"
            :key="item.segment_name"
            class="breadcrumb-item"
          >
            <span v-if="!item.current">
              <router-link
                :to="{
                  path: '/' + item.segment_name,
                  query: Object.assign({}, $route.query),
                }"
              >
                {{ item.title }}
              </router-link>
            </span>
            <span v-else>
              {{ item.title }}
            </span>
          </li>
        </ol>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    items: Array,
  },

  data() {
    return {
      query: "",
      lang: "",
    };
  },

  created() {
    for (let i = 0; i <= this.items.length; i++) {
      if (this.items[i] != undefined) {
        this.items[i].current = i + 1 === this.items.length ? true : false;
      }
    }
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  methods: {
    routeCheck() {
      const name = this.$route.name;
      return name == "blog" || name == "blogDetail" ? true : false;
    },
  },
};
</script>

<style lang="scss">
.breadcrumb {
  padding: 0.75rem 50px;
  background: none;

  .breadcrumb-item {
    font-size: 14px;
  }
}

.container-fluid {
  .breadcrumb {
    padding: 0.75rem 0;
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .breadcrumb {
    padding: 0.75rem 30px;
  }
}

/* SP Only */
@media (max-width: 767px) {
  .breadcrumb {
    padding: 0.75rem 20px;
  }
}
</style>
