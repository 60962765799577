<template>
  <section
    v-if="recommend && blog.length > 0"
    class="iv2-section-category theme__sub-bg"
  >
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full text-center theme__heading">
          <h3 class="theme__heading-text">
            {{ $store.state.themeLang.general.category_title }}
          </h3>
          <p class="theme__heading-subtext">
            {{ $store.state.themeLang.general.category_subtitle }}
          </p>
        </div>
        <div class="column full">
          <div class="spacer height-80"></div>
        </div>
      </div>
      <div class="iv2-top-category row">
        <div class="iv2-top-category__content col-12 col-md-7">
          <div class="iv2-top-category__detail theme__main-bg">
            <h4
              class="iv2-top-category__detail--name theme__pickup--category mt-0"
            >
              {{ category.name }}
            </h4>
            <p class="iv2-top-category__detail--description">
              {{ category.news_recommend_category.description }}
            </p>
            <p class="font-weight-bold">
              {{ $store.state.themeLang.general.search_tag }}
            </p>
            <router-link
              v-for="tag in tags"
              :key="tag.id"
              class="iv2-top-category__tags"
              :to="{
                name: 'blog',
                query: { tag_id: tag.id },
              }"
              ># {{ tag.name }}</router-link
            >
          </div>
        </div>
        <div class="iv2-top-category__article col-12 col-md-5">
          <div>
            <router-link
              :to="{
                name: 'blogDetail',
                params: { id: blog[0].id },
                query: Object.assign({}, $route.query),
              }"
              class="iv2-top-category__article--link"
            >
              <picture>
                <source
                  type="image/webp"
                  :srcset="
                    blog[0].thumbnail +
                    $store.state.publicLang.general.image_webp
                  "
                />
                <img
                  :src="blog[0].thumbnail"
                  class="iv2-top-category__article--img"
                  :alt="
                    '「' +
                    blog[0].title +
                    '」' +
                    $store.state.publicLang.news.alt
                  "
                />
              </picture>
              <p class="iv2-top-category__article--title mb-4">
                {{ blog[0].title }}
              </p>
            </router-link>
            <div class="text-right">
              <router-link
                :to="{
                  name: 'blog',
                  query: { category_id: category.id },
                }"
                class="iv2-top-category__article--btn theme__main-btn"
                >{{
                  $store.state.themeLang.general.category_button
                }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      blog: [],
      category: {},
      tags: [],
      params: {
        type: "blog",
        limit: 1,
        category_id: 0,
      },
      tagParams: {
        is_recommend: 1,
      },
      recommend: false,
    };
  },

  mounted() {
    const blogRepository = this.$repository.get("news");
    const blog = blogRepository.categories(this.params.type, 1);
    const self = this;
    blog
      .then((result) => {
        const res = result.data.response;
        if (!Array.isArray(res.categories) && res.has_news == 1) {
          self.recommend = true;
          self.category = res.categories;
          self.params.category_id =
            res.categories.news_recommend_category.category_id;
          self.getBlog();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },

  methods: {
    getBlog() {
      const blogRepository = this.$repository.get("news");
      const blog = blogRepository.index(this.params);
      const self = this;
      blog
        .then((result) => {
          self.blog = result.data.response.data;
          self.getTags();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getTags() {
      const blogRepository = this.$repository.get("news");
      const blog = blogRepository.tag(this.tagParams);
      const self = this;
      blog
        .then((result) => {
          self.tags = result.data.response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
.iv2-section-category {
  padding: 80px 0;

  .iv2-top-category {
    flex-direction: row-reverse;

    .iv2-top-category__article {
      left: 50px;
      top: -30px;
      z-index: 1;

      .iv2-top-category__article--link {
        &:hover {
          text-decoration: none;

          .iv2-top-category__article--img {
            transform: scale(1.03);
          }
        }

        .iv2-top-category__article--img {
          width: 100%;
          margin: 0;
          border-radius: 10px;
          transition: 0.3s;
        }

        .iv2-top-category__article--title {
          font-weight: bold;
          line-height: 1.4;
          padding-right: 50px;
        }
      }

      .iv2-top-category__article--btn {
        max-width: 100%;
        padding: 10px 50px;
        margin-right: 50px;
        white-space: nowrap;
      }
    }

    .iv2-top-category__detail {
      padding: 50px;
      border-radius: 10px;

      .iv2-top-category__detail--name {
        font-weight: bold;
        font-size: 1.3rem;

        &::after {
          content: "";
          width: 50px;
          border-bottom: 1px solid;
          display: block;
          position: relative;
          bottom: -8px;
        }
      }

      .iv2-top-category__detail--description {
        margin-bottom: 50px;
      }

      .iv2-top-category__tags {
        display: inline-block;
        padding: 0 20px;
        border: 1px solid;
        border-radius: 30px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 14px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-section-category {
    .iv2-top-category {
      .iv2-top-category__detail {
        .iv2-top-category__detail--description {
          line-height: 1.75;
          font-size: 14px;
          margin-bottom: 30px;
        }

        .iv2-top-category__tags {
          font-size: 12px;
        }
      }
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-section-category {
    .iv2-top-category {
      .iv2-top-category__detail {
        padding: 30px 50px;
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-section-category {
    .iv2-top-category {
      overflow: hidden;

      .iv2-top-category__article {
        left: 40px;

        .iv2-top-category__article--link {
          .iv2-top-category__article--title {
            padding-right: 15px;
            font-size: 14px;
          }
        }
      }

      .iv2-top-category__content {
        left: -40px;
      }

      .iv2-top-category__detail {
        padding: 50px 30px;
      }
    }
  }
}
</style>
