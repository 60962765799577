<template>
  <section
    v-if="items.is_open_page_rank === 1 && indexOpenType === 1"
    class="iv2-section-rank theme__sub-bg position-relative"
  >
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full text-center theme__heading" style="z-index: 1">
          <h3 class="iv2-top-rank__title theme__heading-text">
            {{ $store.state.themeLang.general.weekly_rank_title }}
          </h3>
          <p class="iv2-top-rank__subtitle theme__heading-subtext">
            {{ $store.state.themeLang.general.weekly_rank_subtitle }}
          </p>
        </div>

        <div class="column full">
          <div class="spacer height-40"></div>
        </div>

        <div
          v-if="first.id != undefined"
          class="iv2-top-rank-item col-12 col-lg-6"
        >
          <router-link
            :to="{ name: 'blogDetail', params: { id: first.id } }"
            class="iv2-top-rank-item__link"
          >
            <div class="iv2-top-rank-item__rank-label first">
              {{ first.rankNum }}
            </div>
            <div class="iv2-top-rank-item__img">
              <picture>
                <source
                  type="image/webp"
                  :srcset="
                    first.thumbnail + $store.state.publicLang.general.image_webp
                  "
                />
                <img
                  class="m-0"
                  :src="first.thumbnail"
                  :alt="
                    '「' + first.title + '」' + $store.state.publicLang.news.alt
                  "
                />
              </picture>
            </div>
            <div>
              <small
                :class="categoryType(first.category_name)"
                class="iv2-top-rank-item__label"
                >{{ first.category_name }}</small
              >
            </div>
            <h4 class="iv2-top-rank-item__title">{{ first.title }}</h4>
          </router-link>
        </div>

        <div v-if="thereAfter.length > 0" class="col-12 col-lg-6">
          <div v-if="thereAfter" class="row clearfix">
            <div
              v-for="item in thereAfter"
              :key="item.id"
              class="col-6 pr-sm-1 pl-sm-1 pr-0 pl-0 mb-3 mb-sm-4"
            >
              <div class="iv2-top-rank-item">
                <router-link
                  :to="{ name: 'blogDetail', params: { id: item.id } }"
                  class="iv2-top-rank-item__link"
                >
                  <div
                    v-if="item.rankNum === 2"
                    class="iv2-top-rank-item__rank-label second"
                  >
                    {{ item.rankNum }}
                  </div>
                  <div
                    v-else-if="item.rankNum === 3"
                    class="iv2-top-rank-item__rank-label third"
                  >
                    {{ item.rankNum }}
                  </div>
                  <div v-else class="iv2-top-rank-item__rank-label">
                    {{ item.rankNum }}
                  </div>
                  <div class="iv2-top-rank-item__img">
                    <picture>
                      <source
                        type="image/webp"
                        :srcset="
                          item.thumbnail +
                          $store.state.publicLang.general.image_webp
                        "
                      />
                      <img
                        class="m-0"
                        :src="item.thumbnail"
                        :alt="
                          '「' +
                          item.title +
                          '」' +
                          $store.state.publicLang.news.alt
                        "
                      />
                    </picture>
                  </div>
                  <div>
                    <small
                      :class="categoryType(item.category_name)"
                      class="iv2-top-rank-item__label"
                      >{{ item.category_name }}</small
                    >
                  </div>
                  <h4 class="iv2-top-rank-item__title">{{ item.title }}</h4>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="column full">
          <div class="text-center">
            <span>
              <router-link
                :to="{
                  name: 'ranking',
                  query: Object.assign({}, $route.query),
                }"
                class="iv2-top-rank-btn theme__main-btn"
                >{{
                  $store.state.themeLang.general.blog_rank_button
                }}</router-link
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: Object,
  },

  data() {
    return {
      first: [],
      thereAfter: [],
      indexOpenType: 0,
      params: {},
    };
  },

  computed: {
    limitCount() {
      return this.blog.slice(0, this.params.limit);
    },
  },

  mounted() {
    const blogRepository = this.$repository.get("news");
    const blog = blogRepository.rank(this.params);
    const self = this;
    blog
      .then((result) => {
        const res = result.data.response;

        if (res.archive && Object.keys(res.archive).length > 0) {
          // 1位を抽出
          self.first = res["archive"][0];
          self.first.rankNum = 1;

          // 2位以降を抽出
          let cnt = 0;
          for (var i = 1; i <= 14; i++) {
            if (res["archive"][i] && cnt <= 3) {
              self.thereAfter[cnt] = res["archive"][i];
              self.thereAfter[cnt].rankNum = cnt + 2;
              cnt ++;
            }
          }

          if (self.$route.query.mode == "preview") {
            self.indexOpenType = 1;
          } else {
            self.indexOpenType = res.blog_open_type;
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });

    this.gridHeight();
  },

  updated() {
    this.gridHeight();
  },

  methods: {
    gridHeight() {
      let title = document.getElementsByClassName("iv2-top-rank-item__title");
      let titleMaxHeight = 0;
      let titleArray = new Array();

      Array.prototype.forEach.call(title, (el) => {
        el.style.height = "";
        titleArray.push(el.clientHeight);
      });

      titleMaxHeight = Math.max.apply(null, titleArray);

      Array.prototype.forEach.call(title, (el) => {
        el.style.height = titleMaxHeight + "px";
      });
    },

    // customize
    categoryType(name) {
      if (name == "インタビュー") {
        return "red-label";
      } else if (name == "就活コラム") {
        return "blue-label";
      } else if (name == "デジタルブック") {
        return "orange-label";
      } else if (name == "イベント情報") {
        return "green-label";
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-section-rank {
  padding: 80px 0;

  .iv2-top-rank__background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 450px;
  }

  .iv2-top-rank__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }

  .iv2-top-rank__subtitle {
    font-size: 12px;
    line-height: 1.5;
    margin: 0;
  }

  .iv2-top-rank-item {
    padding-right: 5px;
    padding-left: 5px;

    .iv2-top-rank-item__link {
      display: block;
      position: relative;
      text-decoration: none;
      transition: 0.3s;
      -webkit-transition: 0.3s;

      &:hover {
        transform: scale(1.03);

        .iv2-top-rank-item__img {
          opacity: 0.7;
        }
      }

      .iv2-top-rank-item__img {
        margin-bottom: 30px;
        transition: 0.3s;
        position: relative;

        img {
          border-radius: 10px;
          border: 1px solid #f0f0f0;
        }
      }

      .iv2-top-rank-item__title {
        font-size: 1.1rem;
        font-weight: bold;
        margin: 20px 0;
      }

      .iv2-top-rank-item__label {
        text-align: center;
        border: 1px solid;
        border-radius: 30px;
        font-size: 14px;
        font-weight: 600;
        padding: 5px 25px;
        border-color: #0d5898;
        color: #0d5898;

        &.red-label {
          border-color: #de0000;
          color: #de0000;
        }

        &.blue-label {
          border-color: #0d9bd0;
          color: #0d9bd0;
        }

        &.orange-label {
          border-color: #de8000;
          color: #de8000;
        }

        &.green-label {
          border-color: #158600;
          color: #158600;
        }
      }

      .iv2-top-rank-item__rank-label {
        position: absolute;
        top: -20px;
        left: 15px;
        z-index: 10;
        width: 45px;
        height: 45px;
        color: #fff;
        background: #171717;
        border-radius: 50px;
        font-size: 24px;
        text-align: center;
        line-height: 1.8;

        &.first {
          left: 25px;
          background-color: #e6ba00;
        }
        &.second {
          background-color: #a9b3b9;
        }
        &.third {
          background-color: #9d7826;
        }
      }

      .iv2-top-rank-item__date {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .iv2-top-rank-btn {
    letter-spacing: 2px;
    max-width: 100%;
    padding: 10px 80px;
    margin: 10px 13px;
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-section-rank {
    .iv2-top-rank-item {
      padding-right: 5px;
      padding-left: 5px;

      .iv2-top-rank-item__link {
        .iv2-top-rank-item__img {
          margin-bottom: 1rem;
        }

        .iv2-top-rank-item__label {
          display: inline-block;
          padding: 0 20px;
          margin-bottom: 10px;
          font-size: 10px;
        }

        .iv2-top-rank-item__date {
          font-size: 10px;
        }

        .iv2-top-rank-item__title {
          font-size: 14px;
        }

        .iv2-top-rank-item__rank-label {
          top: -15px;
          left: 15px;
          width: 35px;
          height: 35px;
          font-size: 18px;
          line-height: 2;
        }
      }
    }
  }
}
</style>
