<template>
  <section>
    <div class="iv2-hero">
      <div
        v-if="items.main_visual_type == 'slider'"
        :class="displayType"
        class="iv2-hero-slider position-relative overflow-hidden"
      >
        <div
          v-for="media in items.main_visuals"
          :key="media.id"
          class="iv2-hero-content"
          :class="{ active: currentSlide == media.sort_key }"
        >
          <transition name="slider-fade" appear>
            <div v-if="currentSlide == media.sort_key" class="iv2-hero-inner">
              <!-- PC画像 -->
              <picture :class="setDisplayTypeByDeviceSize(media, 'pc')">
                <source
                  type="image/webp"
                  :srcset="
                    media.file_url + $store.state.publicLang.general.image_webp
                  "
                />
                <img
                  class="iv2-hero-image"
                  :src="media.file_url"
                  :alt="
                    organization.name + $store.state.publicLang.general.hero_alt
                  "
                />
              </picture>
              <!-- PC画像 -->
              <!-- SP画像 -->
              <picture
                v-if="media.file_url_sp != null"
                :class="setDisplayTypeByDeviceSize(media, 'sp')"
              >
                <source
                  type="image/webp"
                  :srcset="
                    media.file_url_sp +
                    $store.state.publicLang.general.image_webp
                  "
                />
                <img
                  class="iv2-hero-image"
                  :src="media.file_url_sp"
                  :alt="
                    organization.name + $store.state.publicLang.general.hero_alt
                  "
                />
              </picture>
              <!-- SP画像 -->
              <div
                v-if="media.use_link === 1"
                class="iv2-hero-cover position-absolute"
              >
                <a
                  v-if="media.use_link === 1 && media.is_external === 0"
                  :href="media.link_href"
                  target="_self"
                  class="iv2-hero-link d-block w-100 h-100"
                ></a>
                <a
                  v-else-if="media.use_link === 1 && media.is_external === 1"
                  :href="media.link_href"
                  target="_blank"
                  class="iv2-hero-link d-block w-100 h-100"
                ></a>
              </div>
              <div class="iv2-hero-catchphrase position-absolute">
                <a
                  v-if="media.use_link === 1 && media.is_external === 0"
                  :href="media.link_href"
                  target="_self"
                  class="iv2-hero-link position-absolute d-block w-100 h-100"
                ></a>
                <a
                  v-else-if="media.use_link === 1 && media.is_external === 1"
                  :href="media.link_href"
                  target="_blank"
                  class="iv2-hero-link position-absolute d-block w-100 h-100"
                ></a>
                <picture
                  v-if="
                    media.catchphrase_type == 'img' &&
                    media.catchphrase_image_url
                  "
                  class="iv2-hero-catchphrase__picture"
                >
                  <source
                    type="image/webp"
                    :srcset="
                      media.catchphrase_image_url +
                      $store.state.publicLang.general.image_webp
                    "
                  />
                  <img
                    :src="media.catchphrase_image_url"
                    :alt="
                      organization.name +
                      $store.state.publicLang.general.catchphrase_alt
                    "
                    class="iv2-hero-catchphrase__img"
                  />
                </picture>
                <div class="iv2-hero-catchphrase__text col-md-12 mx-auto">
                  <h3
                    v-if="media.summary_text"
                    class="iv2-hero__sub-title text-pre"
                  >
                    {{ media.summary_text }}
                  </h3>
                  <p
                    v-if="
                      media.catchphrase_text &&
                      media.summary_text &&
                      media.catchphrase_type == 'text'
                    "
                    class="iv2-hero__border"
                  ></p>
                  <h2
                    v-if="
                      media.catchphrase_type == 'text' && media.catchphrase_text
                    "
                    class="iv2-hero__main-title text-pre"
                  >
                    {{ media.catchphrase_text }}
                  </h2>
                  <div v-if="media.use_link === 1" class="mt-4 mt-xl-5">
                    <a
                      v-if="media.is_external === 0"
                      :href="media.link_href"
                      target="_self"
                      class="iv2-hero-btn"
                      >{{ $store.state.themeLang.general.hero_button }}</a
                    >
                    <a
                      v-else-if="media.is_external === 1"
                      :href="media.link_href"
                      target="_blank"
                      class="iv2-hero-btn"
                      >{{ $store.state.themeLang.general.hero_button }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="iv2-hero__background theme__sub-bg"></div>
        <div v-if="items.main_visuals.length > 1" class="iv2-hero__dots">
          <span
            v-for="(item, index) in items.main_visuals"
            :key="item.id"
            class="iv2-hero__dots-item theme__slider-dots"
            :class="{ active: currentSlide == index + 1 }"
            @click="currentSlide = index + 1"
          ></span>
        </div>
      </div>
      <div
        v-else-if="items.main_visual_type == 'movie'"
        :class="displayType"
        class="iv2-hero-video position-relative overflow-hidden"
      >
        <div class="iv2-hero__background theme__sub-bg"></div>
        <div class="iv2-hero-content">
          <!-- PC用 -->
          <video
            :class="setDisplayTypeByDeviceSize(data, 'pc')"
            class="iv2-hero-content__data fit-img"
            autoplay
            muted
            loop
            playsinline
          >
            <source :src="data.file_url" type="video/mp4" />
          </video>
          <!-- PC用 -->
          <!-- SP用 -->
          <video
            v-if="data.file_url_sp != null"
            :class="setDisplayTypeByDeviceSize(data, 'sp')"
            class="iv2-hero-content__data fit-img"
            autoplay
            muted
            loop
            playsinline
          >
            <source :src="data.file_url_sp" type="video/mp4" />
          </video>
          <!-- SP用 -->

          <div class="iv2-hero-cover position-absolute">
            <a
              v-if="data.use_button === 1 && data.is_external === 0"
              :href="data.link_href"
              target="_self"
              class="iv2-hero-link d-block w-100 h-100"
            ></a>
            <a
              v-else-if="data.use_button === 1 && data.is_external === 1"
              :href="data.link_href"
              target="_blank"
              class="iv2-hero-link d-block w-100 h-100"
            ></a>
          </div>
          <div class="iv2-hero-catchphrase position-absolute">
            <picture
              v-if="
                data.catchphrase_type == 'img' && data.catchphrase_image_url
              "
              class="iv2-hero-catchphrase__picture"
            >
              <source
                type="image/webp"
                :srcset="
                  data.catchphrase_image_url +
                  $store.state.publicLang.general.image_webp
                "
              />
              <img
                :src="data.catchphrase_image_url"
                :alt="
                  organization.name +
                  $store.state.publicLang.general.catchphrase_alt
                "
                class="iv2-hero-catchphrase__img"
              />
            </picture>
            <div class="iv2-hero-catchphrase__text col-md-12 mx-auto">
              <h3 v-if="data.summary_text" class="iv2-hero__sub-title text-pre">
                {{ data.summary_text }}
              </h3>
              <p
                v-if="
                  data.catchphrase_text &&
                  data.summary_text &&
                  data.catchphrase_type == 'text'
                "
                class="iv2-hero__border"
              ></p>
              <h2
                v-if="data.catchphrase_type == 'text' && data.catchphrase_text"
                class="iv2-hero__main-title text-pre"
              >
                {{ data.catchphrase_text }}
              </h2>
              <div v-if="data.use_button === 1" class="mt-5">
                <a
                  :href="data.link_href"
                  :target="windowSelected"
                  class="iv2-hero-btn"
                  >{{ data.button_label }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: Object,
    organization: Object,
  },

  data() {
    return {
      currentSlide: 1,
      displayType: "",
      windowSelected: "",
      data: {},
    };
  },

  created() {
    if (this.items.main_visual_type == "movie") {
      this.data = this.items.main_visuals[0];
      if (this.data.is_external == 1) {
        this.windowSelected = "_blank";
      } else {
        this.windowSelected = "_self";
      }
    }

    this.displayType = this.items.display_type;
  },

  mounted() {
    if (
      this.items.main_visual_type == "slider" &&
      this.items.main_visuals.length > 1
    ) {
      setInterval(() => {
        this.currentSlide =
          this.currentSlide < this.items.main_visuals.length
            ? this.currentSlide + 1
            : 1;
      }, 6000);
    }
  },
  methods: {
    // メインビジュアルのスライドごとの表示クラス制御
    setDisplayTypeByDeviceSize(media = {}, type = "pc") {
      let ret = "";
      if (media != {} && media.file_url_sp != null) {
        switch (type) {
          // SP時
          case "sp":
            ret =
              this.items.main_visual_type == "slider"
                ? "d-sm-none d-inline"
                : "d-sm-none d-block";
            break;
          // その他(デフォルト:PC)
          default:
            ret =
              this.items.main_visual_type == "slider"
                ? "d-sm-inline d-none"
                : "d-sm-block d-none";
        }
      }
      return ret;
    },
  },
};
</script>

<style lang="scss">
.slider-fade-enter-active,
.slider-fade-leave-active {
  transition: opacity 1s;
}
.slider-fade-enter,
.slider-fade-leave-to {
  opacity: 0;
}

.iv2-hero {
  .full_screen {
    height: 100vh;
    object-fit: cover;
  }

  .responsive.iv2-hero-slider {
    height: 58vw; // customize
    // height: 100vh;
    object-fit: cover;
  }

  .full_screen {
    &.iv2-hero-video {
      .iv2-hero-content {
        height: 100%;

        .iv2-hero-content__data {
          height: 100%;
        }
      }
    }
  }

  .iv2-hero-slider {
    .iv2-hero-content {
      position: absolute;
      height: 100%;

      .iv2-hero-inner {
        width: 100%;
        height: 100%;
        position: relative;

        .iv2-hero-image {
          margin: 0 !important;
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
      }
    }

    .iv2-hero__dots {
      z-index: 11;
      position: absolute;
      left: 41px;
      top: 50%;
      transform: translateY(-50%);

      .iv2-hero__dots-item {
        display: block;
        width: 8px;
        height: 20px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }
  }

  .iv2-hero-video {
    .iv2-hero-content {
      .iv2-hero-content__data {
        margin: 0 !important;
        width: 100%;
        object-fit: cover;
        display: block;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }

    .iv2-hero-cover {
      display: none;
    }
  }

  .iv2-hero-content {
    width: 100%;
    z-index: 1;

    &.active {
      z-index: 2;
    }
  }

  .iv2-hero-cover {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .iv2-hero-link {
    cursor: pointer;
    z-index: 21;
  }

  .iv2-hero-catchphrase {
    padding-right: 100px;
    bottom: 110px;
    left: 230px;
    color: #ffffff;
    z-index: 11;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);

    .iv2-hero-catchphrase__picture {
      width: 400px;

      .iv2-hero-catchphrase__img {
        max-height: 300px;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
      }
    }

    .iv2-hero-catchphrase__text {
      .iv2-hero__main-title,
      .iv2-hero__sub-title {
        font-weight: 600;
      }

      .iv2-hero__border {
        border-bottom: 2px solid;
        width: 40px;
        display: inline-block;
        margin: 10px 0;
      }
    }
  }

  .iv2-hero-btn {
    max-width: 100%;
    padding: 10px 50px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 20px;
    background-color: transparent;
    text-decoration: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  .iv2-hero__background {
    position: absolute;
    bottom: 0;
    height: 50%;
    width: 100%;
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-hero {
    .responsive {
      &.iv2-hero-video {
        height: calc(100vh - 100px);

        .iv2-hero-content {
          height: 100%;

          .iv2-hero-content__data {
            height: 100%;
          }
        }
      }
    }

    .iv2-hero-content {
      padding-left: 90px;
      padding-bottom: 45px;
    }

    .iv2-hero-catchphrase {
      .iv2-hero-catchphrase__text {
        .iv2-hero__main-title {
          font-size: 26px;
        }

        .iv2-hero__sub-title {
          font-size: 18px;
        }
      }
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-hero {
    .responsive {
      // &.iv2-hero-slider {
      //   height: 50vh;
      // }

      .iv2-hero-catchphrase {
        .iv2-hero-catchphrase__picture {
          .iv2-hero-catchphrase__img {
            max-height: 250px;
          }
        }
      }
    }

    .iv2-hero-content {
      padding-left: 60px;
      padding-bottom: 40px;
    }

    .iv2-hero-slider {
      .iv2-hero__dots {
        left: 25px;
      }
    }

    .iv2-hero-catchphrase {
      bottom: 90px;
      left: 100px;

      .iv2-hero-catchphrase__text {
        .iv2-hero__main-title {
          font-size: 20px;
        }

        .iv2-hero__sub-title {
          font-size: 1rem;
        }
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-hero {
    .full_screen {
      height: calc(100vh - 110px);

      .iv2-hero-catchphrase {
        bottom: 70px;
        left: 50px;
        padding-right: 50px;

        .iv2-hero-catchphrase__picture {
          width: 90%;

          .iv2-hero-catchphrase__img {
            max-height: 250px;
          }
        }
        .iv2-hero-catchphrase__text {
          .iv2-hero__main-title {
            font-size: 18px;
          }

          .iv2-hero__sub-title {
            font-size: 12px;
          }
        }
      }
    }

    .responsive {
      // &.iv2-hero-slider {
      //   height: 30vh;
      // }

      &.iv2-hero-video {
        .iv2-hero-cover {
          display: block;
          width: calc(100% - 20px);
          height: calc(100% - 20px);
        }
      }

      .iv2-hero-catchphrase {
        padding-right: 0;
        bottom: initial;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .iv2-hero-catchphrase__picture {
          width: 80%;

          .iv2-hero-catchphrase__img {
            max-height: 150px;
          }
        }

        .iv2-hero-catchphrase__text,
        .iv2-hero-btn {
          display: none;
        }
      }
    }

    .iv2-hero-content {
      padding-left: 20px;
      padding-bottom: 20px;
    }

    .iv2-hero-slider {
      .iv2-hero__dots {
        display: none;
      }
    }
  }
}
</style>
