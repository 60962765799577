var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.items.is_open_news == 1 &&
    _vm.news.length > 0 &&
    _vm.params.limit > 0 &&
    _vm.indexOpenType == 1
  )?_c('section',{staticClass:"iv2-section-news position-relative pb-0"},[_c('div',{staticClass:"theme__sub-bg"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"column full text-center theme__heading"},[_c('h3',{staticClass:"iv2-top-news__title theme__heading-text"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"iv2-top-news__subtitle theme__heading-subtext"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])]),_vm._m(1)]),(_vm.type == 'list')?_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"column full"},_vm._l((_vm.limitCount),function(item){return _c('NewsLine',{key:item.id,attrs:{"content":item}})}),1)]):(_vm.type == 'tile')?_c('div',{staticClass:"row clearfix"},_vm._l((_vm.limitCount),function(item){return _c('div',{key:item.id,staticClass:"mb-5 mb-xl-4 col-xl-6 col-12 p-0"},[_c('NewsTile',{attrs:{"content":item}})],1)}),0):_vm._e(),_c('div',{staticClass:"row clearfix"},[_vm._m(2),_c('div',{staticClass:"column full"},[_c('div',{staticClass:"text-center"},[_c('span',[_c('router-link',{staticClass:"iv2-top-news-btn theme__main-btn",attrs:{"to":{
                  name: 'news',
                  query: Object.assign({}, _vm.$route.query),
                }}},[_vm._v(_vm._s(_vm.$store.state.publicLang.page.news_button))])],1)])])]),_vm._m(3)])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"column full"},[_c('div',{staticClass:"spacer height-100"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column full"},[_c('div',{staticClass:"spacer height-40"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column full"},[_c('div',{staticClass:"spacer height-40"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row clearfix full-width__inner"},[_c('div',{staticClass:"column full"},[_c('div',{staticClass:"spacer height-100"})])])}]

export { render, staticRenderFns }