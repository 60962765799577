<template>
  <div v-if="lastPage > 1" class="container-fluid">
    <div class="row clearfix">
      <div class="column full text-center d-none d-md-block">
        <paginate
          v-model="current"
          :page-count="lastPage"
          :page-range="7"
          :margin-pages="2"
          :click-handler="pagination"
          :prev-text="prev"
          :next-text="next"
          :container-class="'pagination d-inline-flex'"
          :page-class="'page-item theme__pagination'"
          :page-link-class="'page-link'"
        >
        </paginate>
      </div>
      <div class="column full text-center d-md-none">
        <paginate
          v-model="current"
          :page-count="lastPage"
          :showPages="3"
          :margin-pages="0"
          :click-handler="pagination"
          :prev-text="prev"
          :next-text="next"
          :container-class="'pagination d-inline-flex'"
          :page-class="'page-item theme__pagination'"
          :page-link-class="'page-link'"
        >
        </paginate>
      </div>
      <div class="column full">
        <div class="spacer height-100"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lastPage: Number,
    sortPage: Number,
  },

  data() {
    return {
      current: 1,
      prev: '<div class="theme__pagination--prev"></div>',
      next: '<div class="theme__pagination--next"></div>',
    };
  },

  watch: {
    $route(to, from) {
      if (to.query !== from.query) {
        this.current = this.sortPage;
      }
    },
  },

  created() {
    this.current = this.sortPage;
  },

  methods: {
    pagination(page) {
      this.$emit("pagination", page);
    },
  },
};
</script>

<style lang="scss"></style>
