<template>
  <aside
    v-if="useCheck()"
    class="iv2-extra-sns theme__extra-sns-bg text-center d-xl-block"
  >
    <div class="iv2-extra-sns-nav m-auto">
      <ul class="iv2-extra-sns-nav-list list-unstyled">
        <li
          v-if="sns.is_linked_line == 1"
          class="iv2-extra-sns-nav-item theme__extra-sns-item mx-2"
        >
          <a
            :href="sns.line_url"
            class="iv2-extra-sns-nav-link theme__extra-sns-link"
            target="_blank"
            rel="noopener"
          >
            <i class="iv2-extra-sns-nav-item-icon fab fa-line"></i>
          </a>
        </li>
        <li
          v-if="sns.is_linked_facebook == 1"
          class="iv2-extra-sns-nav-item theme__extra-sns-item mx-2"
        >
          <a
            :href="sns.facebook_url"
            class="iv2-extra-sns-nav-link theme__extra-sns-link"
            target="_blank"
            rel="noopener"
          >
            <i
              class="iv2-extra-sns-nav-item-icon fab fa-facebook-f"
              style="font-size: 2.4rem"
            ></i>
          </a>
        </li>

        <li
          v-if="sns.is_linked_twitter == 1"
          class="iv2-extra-sns-nav-item theme__extra-sns-item mx-2"
        >
          <a
            :href="sns.twitter_url"
            class="iv2-extra-sns-nav-link theme__extra-sns-link"
            target="_blank"
            rel="noopener"
          >
            <i class="iv2-extra-sns-nav-item-icon fab fa-twitter"></i>
          </a>
        </li>
        <li
          v-if="sns.is_linked_instagram == 1"
          class="iv2-extra-sns-nav-item theme__extra-sns-item mx-2"
        >
          <a
            :href="sns.instagram_url"
            class="iv2-extra-sns-nav-link theme__extra-sns-link"
            target="_blank"
            rel="noopener"
          >
            <i class="iv2-extra-sns-nav-item-icon fab fa-instagram"></i>
          </a>
        </li>
        <!-- <li
          v-if="rss.is_linked_blog == 1"
          class="iv2-extra-sns-nav-item theme__extra-sns-item mx-2"
        >
          <a
            :href="rss.link"
            class="iv2-extra-sns-nav-link theme__extra-sns-link"
            target="_blank"
            rel="noopener"
          >
            <i class="iv2-extra-sns-nav-item-icon fas fa-rss"></i>
          </a>
        </li> -->
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    sns: Object,
    rss: Object,
  },

  methods: {
    useCheck() {
      return this.sns.is_linked_line == 1 ||
        this.sns.is_linked_facebook == 1 ||
        this.sns.is_linked_twitter == 1 ||
        this.sns.is_linked_instagram == 1
        ? true
        : false;
    },
  },
};
</script>

<style lang="scss">
.iv2-extra-sns {
  padding: 60px 0;

  .iv2-extra-sns-nav {
    .iv2-extra-sns-nav-list {
      margin: 0;
      display: flex;
      justify-content: center;

      .iv2-extra-sns-nav-item {
        width: 49px;
        height: 49px;
        border-radius: 50%;

        .iv2-extra-sns-nav-link {
          display: block;
          width: 100%;
          height: 100%;
          padding-top: 12px;

          .iv2-extra-sns-nav-item-icon {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}
</style>
