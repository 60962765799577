<template>
  <div>
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full">
          <h3 class="iv2-news-detail__title">{{ news.title }}</h3>
          <div class="iv2-news-detail__subtitle">
            <p v-if="news.opened_at" class="iv2-news-detail__date">
              {{ news.opened_at }}
            </p>
            <p
              v-if="news.category_name"
              :class="categoryType(news.category_name)"
              class="iv2-news-detail__label"
            >
              {{ news.category_name }}
            </p>
          </div>
          <div class="spacer height-40"></div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full">
          <hr class="iv2-news-detail__line" />
        </div>
      </div>
    </div>

    <div class="container-fluid" v-html="news.content"></div>

    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full">
          <div class="spacer height-40"></div>
          <div class="text-center">
            <span>
              <button
                @click="prevNews"
                class="iv2-news-detail__btn theme__main-btn"
              >
                {{ $store.state.publicLang.news.list_button }}
              </button>
            </span>
          </div>
          <div class="spacer height-100"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    news: Object,
    categoryId: String,
    page: String,
  },

  methods: {
    prevNews() {
      if (this.page || this.categoryId) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: "news",
          query: Object.assign({}, this.$route.query),
        });
      }
    },

    // customize
    categoryType(name) {
      if (name == "インタビュー") {
        return "red-label";
      } else if (name == "就活コラム") {
        return "blue-label";
      } else if (name == "デジタルブック") {
        return "orange-label";
      } else if (name == "イベント情報") {
        return "green-label";
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-news-detail__line {
  margin-top: 0;
  margin-bottom: 40px;
}

.iv2-news-detail__title {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 30px 0 0;
}

.iv2-news-detail__subtitle {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0 15px;

  .iv2-news-detail__date {
    font-weight: 600;
    margin-right: 20px;
    color: #777777;
  }

  .iv2-news-detail__label {
    text-align: center;
    border: 1px solid;
    border-radius: 30px;
    padding: 1px 30px;
    font-weight: 600;
    font-size: 14px;
    border-color: #0d5898;
    color: #0d5898;

    &.red-label {
      border-color: #de0000;
      color: #de0000;
    }

    &.blue-label {
      border-color: #0d9bd0;
      color: #0d9bd0;
    }

    &.orange-label {
      border-color: #de8000;
      color: #de8000;
    }

    &.green-label {
      border-color: #158600;
      color: #158600;
    }
  }
}

.iv2-news-detail__btn {
  max-width: 100%;
  padding: 10px 80px;
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-news-detail__title {
    font-size: 1.5rem;
  }
}
/* SP Only */
@media (max-width: 767px) {
  .iv2-news-detail__title {
    font-size: 1.3rem;
  }
}
</style>
