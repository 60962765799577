<template>
  <section
    v-if="
      items.is_open_blog == 1 &&
      blog.length > 0 &&
      params.limit > 0 &&
      indexOpenType == 1
    "
    class="iv2-section-blog position-relative"
  >
    <div class="iv2-top-blog__background theme__sub-bg"></div>
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full text-center theme__heading" style="z-index: 1">
          <h3 class="iv2-top-blog__title theme__heading-text">
            {{ $store.state.themeLang.general.top_blog_title }}
          </h3>
          <p class="iv2-top-blog__subtitle theme__heading-subtext">
            {{ $store.state.themeLang.general.top_blog_subtitle }}
          </p>
        </div>
        <div class="column full">
          <div class="spacer height-40"></div>
        </div>
        <div
          v-for="item in limitCount"
          :key="item.id"
          class="iv2-top-blog__item mb-3 mb-md-4 col-6 col-xl-4"
        >
          <NewsCard :content="item" />
        </div>
        <div class="column full">
          <div class="spacer height-40"></div>
          <div class="text-center">
            <span>
              <router-link
                :to="{ name: 'blog', query: Object.assign({}, $route.query) }"
                class="iv2-top-blog__btn theme__main-btn"
                >{{ $store.state.publicLang.page.blog_button }}</router-link
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NewsCard from "@/components/items/BaseNewsCard.vue";

export default {
  components: {
    NewsCard,
  },

  props: {
    items: Object,
  },

  data() {
    return {
      blog: [],
      indexOpenType: "",
      params: {
        type: "blog",
        limit: "",
      },
    };
  },

  computed: {
    limitCount() {
      return this.blog.slice(0, this.params.limit);
    },
  },

  created() {
    this.params.limit = this.items.display_blog_number;
  },

  mounted() {
    const blogRepository = this.$repository.get("news");
    const blog = blogRepository.index(this.params);
    const self = this;
    blog
      .then((result) => {
        const res = result.data.response;
        self.blog = res.data;
        if (self.$route.query.mode == "preview") {
          self.indexOpenType = 1;
        } else {
          self.indexOpenType = res.blog_open_type;
        }
      })
      .catch((error) => {
        console.error(error);
      });
    this.gridHeight();
  },

  updated() {
    this.gridHeight();
  },

  methods: {
    gridHeight() {
      let title = document.getElementsByClassName("iv2-news-card__title");
      let titleMaxHeight = 0;
      let titleArray = new Array();

      Array.prototype.forEach.call(title, (el) => {
        el.style.height = "";
        titleArray.push(el.clientHeight);
      });

      titleMaxHeight = Math.max.apply(null, titleArray);

      Array.prototype.forEach.call(title, (el) => {
        el.style.height = titleMaxHeight + "px";
      });
    },
  },
};
</script>

<style lang="scss">
.iv2-section-blog {
  padding: 40px 0 80px;

  .iv2-top-blog__item {
    display: flex;
    align-items: stretch;
  }

  .iv2-top-blog__background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 280px;
  }

  .iv2-top-blog__btn {
    letter-spacing: 2px;
    max-width: 100%;
    padding: 10px 80px;
    margin: 10px 13px;
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-section-blog {
    .iv2-top-blog__background {
      height: 200px;
    }

    .iv2-top-blog__item {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}
</style>
