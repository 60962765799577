<template>
  <div class="iv2-news-item__tile">
    <router-link
      :to="{
        name: 'newsDetail',
        params: { id: content.id },
        query: Object.assign({}, $route.query),
      }"
      class="iv2-news-item__link"
    >
      <div class="iv2-news-item__block theme__main-bg row">
        <div class="iv2-news-item__thumbnail fit-img col-sm-3 col-5">
          <picture>
            <source
              type="image/webp"
              :srcset="
                content.thumbnail + $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="content.thumbnail"
              class="iv2-news-item__img m-0"
              :alt="
                '「' + content.title + '」' + $store.state.publicLang.news.alt
              "
            />
          </picture>
        </div>

        <div class="col-md-9 col-7">
          <div class="d-md-flex text-left">
            <p v-if="content.opened_at" class="iv2-news-item__date">
              {{ content.opened_at }}
            </p>
            <span class="iv2-news-item__label my-3">
              {{ content.category_name }}
            </span>
          </div>
          <div class="d-none d-md-block">
            <h3 class="iv2-news-item__title">{{ content.title }}</h3>
          </div>
        </div>
        <div class="d-md-none col-12 p-0">
          <h3 class="iv2-news-item__title mt-2">
            {{ content.title }}
          </h3>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
  },
};
</script>

<style lang="scss">
.iv2-news-item__tile {
  .iv2-news-item__link {
    display: block;
    text-decoration: none;
    height: 100%;

    &:hover .iv2-news-item__thumbnail {
      opacity: 0.7;
    }

    .iv2-news-item__block {
      padding: 20px;
      margin: 0 15px;
      border-radius: 10px;
      height: 100%;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    }

    .iv2-news-item__thumbnail {
      height: 123px;
      max-width: 123px;
      -webkit-transition: 0.3s;
      transition: 0.3s;

      .iv2-news-item__img {
        border-radius: 10px;
      }
    }

    .iv2-news-item__title {
      font-size: 1.1rem;
      font-weight: bold;
    }

    .iv2-news-item__summary {
      font-size: 1rem;
      line-height: 1.5;
    }

    .iv2-news-item__date {
      font-weight: bold;
      margin-right: 30px;
      line-height: 1.75;
    }

    .iv2-news-item__label {
      font-size: 14px;
      border-radius: 25px;
      padding: 3px 25px;
      font-weight: bold;
      background-color: #000;
      color: #fff;
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-news-item__tile {
    .iv2-news-item__link {
      .iv2-news-item__title {
        font-size: 1.1rem;
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-news-item__tile {
    .iv2-news-item__link {
      .iv2-news-item__thumbnail {
        height: 85px;
      }

      .iv2-news-item__date {
        font-size: 16px;
        margin: 0;
      }

      .iv2-news-item__label {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
  }
}
</style>
