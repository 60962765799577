<template>
  <section v-if="items.is_open_pickup === 1">
    <Pickup :limit="items.display_pickup_number" />
  </section>
</template>

<script>
import Pickup from "@/components/items/BasePickup.vue";

export default {
  components: {
    Pickup,
  },

  props: {
    items: Object,
  },
};
</script>

<style lang="scss"></style>
