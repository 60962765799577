<template>
  <div class="container-fluid">
    <div
      v-if="first.length == 0 && thereAfter.length == 0"
      class="iv2-ranking__null row clearfix"
    >
      <div class="column full">
        <div style="padding: 2rem 0">
          <h4 class="size-24 text-center font-weight-bold">
            {{ $store.state.publicLang.news.null_title }}
          </h4>
          <p class="text-center">
            {{ $store.state.publicLang.news.null_summary }}
          </p>
        </div>
        <div class="spacer height-60"></div>
      </div>
    </div>
    <div v-else class="row clearfix">
      <!-- ランキング一位 -->
      <div class="col-12 mb-5 iv2-ranking-first">
        <router-link
          :to="{
            name: 'blogDetail',
            params: { id: first.id },
            query: Object.assign({}, $route.query),
          }"
          class="iv2-ranking-first__link"
        >
          <div class="iv2-ranking-first__card clearfix">
            <div class="iv2-ranking-after__rank">
              {{ first.rankNum }}
            </div>

            <div class="iv2-ranking-first__card-thumbnail float-left">
              <picture>
                <source
                  type="image/webp"
                  :srcset="
                    first.thumbnail + $store.state.publicLang.general.image_webp
                  "
                />
                <img class="m-0" :src="first.thumbnail" :alt="first.title" />
              </picture>
            </div>
            <div class="iv2-ranking-first__card-body float-right">
              <div class="mb-2 mb-md-4">
                <span
                  :class="categoryType(first.category_name)"
                  class="iv2-news-card__label"
                  >{{ first.category_name }}</span
                >
              </div>
              <div class="iv2-ranking-first__card-text">
                {{ first.title }}
              </div>
              <div class="iv2-ranking-first__card-footer row">
                <div v-if="first.opened_at" class="col-6 text-left">
                  {{ first.opened_date }}
                </div>
                <div
                  v-if="first.pageview_number && first.pageview_number > 0"
                  class="iv2-ranking-after__card-pageview col-6 text-right"
                >
                  {{ first.pageview_number.toLocaleString() }}
                  {{ $store.state.publicLang.blog.pageview }}
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <!-- ランキング2位以降 -->
      <div
        v-for="item in thereAfter"
        :key="item.id"
        :class="
          item.rankNum < 4
            ? 'iv2-ranking-after6 col-lg-6 col-md-4 col-6'
            : 'iv2-ranking-after3 col-md-4 col-6'
        "
        class="mb-5"
      >
        <router-link
          :to="{
            name: 'blogDetail',
            params: { id: item.id },
            query: Object.assign({}, $route.query),
          }"
          class="iv2-ranking-after__link"
        >
          <div class="iv2-ranking-after__card">
            <div
              :class="rankClass(item.rankNum)"
              class="iv2-ranking-after__rank"
            >
              {{ item.rankNum }}
            </div>

            <div class="iv2-ranking-after__card-thumbnail">
              <picture>
                <source
                  type="image/webp"
                  :srcset="
                    item.thumbnail + $store.state.publicLang.general.image_webp
                  "
                />
                <img class="m-0" :src="item.thumbnail" :alt="item.title" />
              </picture>
            </div>
            <div class="iv2-ranking__card-body">
              <div class="mb-2 mb-xl-4">
                <span
                  :class="categoryType(item.category_name)"
                  class="iv2-news-card__label"
                  >{{ item.category_name }}</span
                >
              </div>
              <div class="iv2-ranking-after__card-text">
                {{ item.title }}
              </div>
              <div class="iv2-ranking-after__card-footer row">
                <div v-if="item.opened_at" class="col-lg-7 p-0 text-left">
                  {{ item.opened_date }}
                </div>
                <div
                  v-if="item.pageview_number && item.pageview_number > 0"
                  class="iv2-ranking-after__card-pageview col-lg-5 p-0 text-lg-right"
                >
                  {{ item.pageview_number.toLocaleString() }}
                  {{ $store.state.publicLang.blog.pageview }}
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="column full">
        <div class="spacer height-60"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    first: Object,
    thereAfter: Array,
  },

  data() {
    return {
      isActive: 0,
    };
  },

  mounted() {
    this.gridHeight();
  },

  updated() {
    this.gridHeight();
  },

  methods: {
    gridHeight() {
      let title = document.getElementsByClassName("iv2-ranking__card-body");
      let titleMaxHeight = 0;
      let titleArray = new Array();

      Array.prototype.forEach.call(title, (el) => {
        el.style.height = "";
        titleArray.push(el.clientHeight);
      });

      titleMaxHeight = Math.max.apply(null, titleArray);

      Array.prototype.forEach.call(title, (el) => {
        el.style.height = titleMaxHeight + "px";
      });
    },

    rankClass(num = 0) {
      return "iv2-ranking__rank-" + num;
    },

    // customize
    categoryType(name) {
      if (name == "インタビュー") {
        return "red-label";
      } else if (name == "就活コラム") {
        return "blue-label";
      } else if (name == "デジタルブック") {
        return "orange-label";
      } else if (name == "イベント情報") {
        return "green-label";
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-ranking__null {
  padding: 8rem 0;
}

// customize
.iv2-news-card__label {
  text-align: center;
  border: 1px solid;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 25px;
  border-color: #0d5898;
  color: #0d5898;

  &.red-label {
    border-color: #de0000;
    color: #de0000;
  }

  &.blue-label {
    border-color: #0d9bd0;
    color: #0d9bd0;
  }

  &.orange-label {
    border-color: #de8000;
    color: #de8000;
  }

  &.green-label {
    border-color: #158600;
    color: #158600;
  }
}

.iv2-ranking-after__card-pageview {
  color: #acacac;
}

.iv2-ranking-after__rank {
  position: absolute;
  z-index: 10;
  width: 45px;
  height: 45px;
  color: #fff;
  border-radius: 50px;
  font-size: 24px;
  text-align: center;
  line-height: 1.8;
}

.iv2-ranking-first__card-text,
.iv2-ranking-after__card-text {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.8;
}

.iv2-ranking-first__card-footer,
.iv2-ranking-after__card-footer {
  font-size: 14px;
  font-weight: bold;
}

.iv2-ranking-after__card-footer {
  padding: 15px 15px 0;
}

.iv2-ranking-after6,
.iv2-ranking-after3 {
  display: flex;
  align-items: stretch;
}

.iv2-ranking-after6 {
  /* customize */
  // .iv2-ranking-after__card-thumbnail {
  //   height: 280px;
  // }

  .iv2-ranking-after__rank {
    top: -20px;
    left: 25px;
    background: #000;

    &.iv2-ranking__rank-2 {
      background: #a9b3b9;
    }

    &.iv2-ranking__rank-3 {
      background: #9d7826;
    }
  }
}

.iv2-ranking-after3 {
  .iv2-ranking-after__rank {
    top: -20px;
    left: 25px;
    background: #000;
  }

  .iv2-ranking-after__card-text {
    font-size: 18px;
  }

  /* customize */
  // .iv2-ranking-after__card-thumbnail {
  //   height: 140px;
  // }

  .iv2-ranking-after__card-footer {
    font-size: 12px;
  }
}

.iv2-ranking-first__link {
  &:hover {
    text-decoration: none;
  }

  .iv2-ranking-first__card {
    position: relative;
    width: 100%;
    height: 420px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
    transition: 0.3s;
    -webkit-transition: 0.3s;

    &:hover {
      transform: scale(1.03);
      opacity: 0.7;
    }

    .iv2-ranking-after__rank {
      top: -20px;
      left: 25px;
      background-color: #e6ba00;
    }

    .iv2-ranking-first__card-thumbnail {
      position: relative;
      overflow: hidden;
      width: 748px;
      height: 420px;
      border-radius: 10px 0 0 10px;

      img {
        top: 50%;
        left: 50%;
        // height: 100%;
        /* customize */
        // position: absolute;
        // -webkit-transform: translate(-50%, -50%);
        // -ms-transform: translate(-50%, -50%);
        // transform: translate(-50%, -50%);
      }
    }

    .iv2-ranking-first__card-body {
      width: 360px;
      height: 420px;
      padding: 50px 30px;
      position: relative;

      .iv2-ranking-first__card-footer {
        position: absolute;
        bottom: 40px;
        width: 300px;
      }
    }
  }
}

.iv2-ranking-after__link {
  display: block;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  width: 100%;

  &:hover {
    transform: scale(1.03);
    opacity: 0.7;
    text-decoration: none;
  }

  .iv2-ranking-after__card {
    position: relative;

    .iv2-ranking-after__card-thumbnail {
      position: relative;
      overflow: hidden;
      border-radius: 10px 10px 0 0;

      img {
        top: 50%;
        left: 50%;
        width: 100%;
        /* customize */
        // position: absolute;
        // -webkit-transform: translate(-50%, -50%);
        // -ms-transform: translate(-50%, -50%);
        // transform: translate(-50%, -50%);
      }
    }

    .iv2-ranking__card-body {
      position: relative;
      padding: 30px 30px 60px;

      .iv2-ranking-after__card-text {
        margin-bottom: 20px;
      }

      .iv2-ranking-after__card-footer {
        position: absolute;
        bottom: 40px;
        left: 30px;
        right: 30px;
      }
    }
  }
}

/* PC */
@media (min-width: 1200px) {
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-ranking-after__link {
    .iv2-ranking__card-body {
      padding: 20px;

      .iv2-news-card__label {
        display: inline-block;
        padding: 0 20px;
        margin-bottom: 10px;
        font-size: 10px;
      }
    }
  }

  .iv2-ranking-first__link {
    .iv2-ranking-first__card {
      height: 345px;

      .iv2-ranking-first__card-thumbnail {
        width: 550px;
        height: 345px;
      }

      .iv2-ranking-first__card-body {
        height: 345px;
      }
    }
  }
}

@media (max-width: 991px) {
  .iv2-ranking-first__link {
    .iv2-ranking-first__card {
      height: auto;

      .iv2-ranking-first__card-thumbnail {
        width: 100%;
        height: auto;
        border-radius: 10px 10px 0 0;

        img {
          width: 100%;
        }
      }

      .iv2-ranking-first__card-body {
        width: 100%;
        height: auto;
        padding: 30px 30px;

        .iv2-ranking-first__card-text {
          margin-bottom: 20px;
        }

        .iv2-ranking-first__card-footer {
          width: 100%;
          position: initial;
        }
      }
    }
  }

  .iv2-ranking-after__link {
    .iv2-ranking-after__card {
      .iv2-ranking__card-body {
        padding: 15px 15px 60px;

        .iv2-ranking-after__card-text {
          font-size: 14px;
        }

        .iv2-ranking-after__card-footer {
          bottom: 20px;
          padding: 0;
          font-size: 10px;
        }
      }
    }
  }

  .iv2-ranking-after6,
  .iv2-ranking-after3 {
    .iv2-ranking-after__rank {
      top: -15px;
      left: 15px;
      width: 35px;
      height: 35px;
      font-size: 18px;
      line-height: 2;
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-ranking-first__card-text {
    font-size: 18px;
  }

  .iv2-ranking-first__card-footer {
    font-size: 12px;
  }

  .iv2-ranking-first,
  .iv2-ranking-after6,
  .iv2-ranking-after3 {
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 2rem !important;
  }
}
</style>
