<template>
  <form @submit.prevent="search">
    <div class="iv2-search theme__search">
      <input
        v-model="field"
        type="text"
        class="iv2-search__input"
        :placeholder="$store.state.themeLang.general.search_placeholder"
      />
      <button class="iv2-search__button">
        <i class="fas fa-search"></i>
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      field: "",
    };
  },

  methods: {
    search() {
      if (this.$route.query.mode == "preview") {
        this.$router.push({
          name: "blog",
          query: { mode: "preview", keywords: this.field },
        });
      } else {
        this.$router.push({
          name: "blog",
          query: { keywords: this.field },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-search {
  display: flex;
  border-radius: 30px;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  &__input {
    border: 0;
    padding: 0 25px;
    height: 60px;
    width: 90%;
    font-weight: bold;
    border-radius: 0;

    &::placeholder {
      color: #969696;
    }
  }

  &__button {
    border: none;
    padding: 0 10px;
    height: 60px;
    width: 13%;
    font-size: 1.75rem;
    color: #fff;
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-search {
    &__input {
      height: 50px;
    }

    &__button {
      height: 50px;
      font-size: 1.25rem;
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-search {
    &__input {
      height: 40px;
    }

    &__button {
      height: 40px;
      font-size: 1rem;
    }
  }
}
</style>
