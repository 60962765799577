var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.recommend && _vm.blog.length > 0)?_c('section',{staticClass:"iv2-section-category theme__sub-bg"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"column full text-center theme__heading"},[_c('h3',{staticClass:"theme__heading-text"},[_vm._v(" "+_vm._s(_vm.$store.state.themeLang.general.category_title)+" ")]),_c('p',{staticClass:"theme__heading-subtext"},[_vm._v(" "+_vm._s(_vm.$store.state.themeLang.general.category_subtitle)+" ")])]),_vm._m(0)]),_c('div',{staticClass:"iv2-top-category row"},[_c('div',{staticClass:"iv2-top-category__content col-12 col-md-7"},[_c('div',{staticClass:"iv2-top-category__detail theme__main-bg"},[_c('h4',{staticClass:"iv2-top-category__detail--name theme__pickup--category mt-0"},[_vm._v(" "+_vm._s(_vm.category.name)+" ")]),_c('p',{staticClass:"iv2-top-category__detail--description"},[_vm._v(" "+_vm._s(_vm.category.news_recommend_category.description)+" ")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$store.state.themeLang.general.search_tag)+" ")]),_vm._l((_vm.tags),function(tag){return _c('router-link',{key:tag.id,staticClass:"iv2-top-category__tags",attrs:{"to":{
              name: 'blog',
              query: { tag_id: tag.id },
            }}},[_vm._v("# "+_vm._s(tag.name))])})],2)]),_c('div',{staticClass:"iv2-top-category__article col-12 col-md-5"},[_c('div',[_c('router-link',{staticClass:"iv2-top-category__article--link",attrs:{"to":{
              name: 'blogDetail',
              params: { id: _vm.blog[0].id },
              query: Object.assign({}, _vm.$route.query),
            }}},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.blog[0].thumbnail +
                  _vm.$store.state.publicLang.general.image_webp}}),_c('img',{staticClass:"iv2-top-category__article--img",attrs:{"src":_vm.blog[0].thumbnail,"alt":'「' +
                  _vm.blog[0].title +
                  '」' +
                  _vm.$store.state.publicLang.news.alt}})]),_c('p',{staticClass:"iv2-top-category__article--title mb-4"},[_vm._v(" "+_vm._s(_vm.blog[0].title)+" ")])]),_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"iv2-top-category__article--btn theme__main-btn",attrs:{"to":{
                name: 'blog',
                query: { category_id: _vm.category.id },
              }}},[_vm._v(_vm._s(_vm.$store.state.themeLang.general.category_button))])],1)],1)])])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column full"},[_c('div',{staticClass:"spacer height-80"})])}]

export { render, staticRenderFns }