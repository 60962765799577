<template>
  <header
    :class="{ scroll: scrollY > 300 }"
    class="iv2-global theme__main-bg position-fixed"
  >
    <div class="iv2-global__items theme__global-text">
      <h1 class="iv2-global__logo">
        <div class="iv2-global__logo-block">
          <router-link
            v-if="information.sp_logo_image_header"
            :to="'/' + lang + query"
            class="iv2-global__link"
          >
            <picture class="d-none d-md-inline">
              <source
                type="image/webp"
                :srcset="
                  information.logo_image_header +
                  $store.state.publicLang.general.image_webp
                "
              />
              <img
                :src="information.logo_image_header"
                class="iv2-global__logo-img"
                :alt="organization.name"
              />
            </picture>
            <picture class="d-md-none">
              <source
                type="image/webp"
                :srcset="
                  information.sp_logo_image_header +
                  $store.state.publicLang.general.image_webp
                "
              />
              <img
                :src="information.sp_logo_image_header"
                class="iv2-global__logo-img"
                :alt="organization.name"
              />
            </picture>
          </router-link>
          <router-link v-else :to="'/' + lang + query" class="iv2-global__link">
            <picture>
              <source
                type="image/webp"
                :srcset="
                  information.logo_image_header +
                  $store.state.publicLang.general.image_webp
                "
              />
              <img
                :src="information.logo_image_header"
                class="iv2-global__logo-img"
                :alt="organization.name"
              />
            </picture>
          </router-link>
        </div>
      </h1>
      <div class="position-relative w-25 d-none d-xl-block">
        <p class="iv2-global__heading-text">{{ organization.free_text }}</p>
      </div>
      <div @click="openSearch()" class="iv2-global__search">
        <div class="iv2-global__search--block">
          <i class="iv2-global__search--icon fas fa-search"></i>
          <p class="iv2-global__search--text">
            {{ $store.state.themeLang.general.search }}
          </p>
        </div>
      </div>
      <div
        @click="touchHandler"
        class="iv2-global__menu text-center"
        :class="{
          'theme__global-bg': !activeMenu,
          'theme__nav-bg': activeMenu,
        }"
      >
        <div class="iv2-global__menu-block">
          <div class="iv2-global__menu-btn">
            <span
              v-if="!activeMenu"
              class="iv2-global__drawer theme__menu-text"
            >
              {{ $store.state.themeLang.general.menu }}
            </span>
            <span
              v-else
              class="iv2-global__drawer theme__menu-text theme__nav-text"
            >
              {{ $store.state.themeLang.general.close }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <nav
        v-if="activeMenu"
        class="iv2-nav theme__nav-bg theme__nav-text position-fixed vh-100"
      >
        <div class="container-fluid">
          <div class="row flex-row-reverse">
            <div class="col-xl-6 p-0">
              <div class="iv2-nav__block">
                <ul class="iv2-nav__list list-unstyled">
                  <li
                    v-for="nav in global"
                    :key="nav.id"
                    class="iv2-nav__item theme__nav-item"
                  >
                    <router-link
                      v-if="nav.link_type == 'internal'"
                      @click.native="closeMenu"
                      :to="nav.link_href + lang + query"
                      :target="nav.target_type"
                      class="iv2-nav__link theme__nav-link"
                      ><span
                        class="iv2-nav__link--text effect"
                        :class="{ display: menuAnimation }"
                        >{{ nav.item_name }}</span
                      ><span class="iv2-nav__link--text overlay">{{
                        nav.item_name
                      }}</span></router-link
                    >
                    <a
                      v-else-if="nav.link_type == 'external'"
                      @click="closeMenu"
                      :href="nav.link_href"
                      :target="nav.target_type"
                      rel="noopener"
                      class="iv2-nav__link theme__nav-link"
                      ><span
                        class="iv2-nav__link--text effect"
                        :class="{ display: menuAnimation }"
                        >{{ nav.item_name }}</span
                      ><span class="iv2-nav__link--text overlay">{{
                        nav.item_name
                      }}</span></a
                    >
                    <ul
                      v-if="!nav.children.length == false"
                      class="iv2-nav__list list-unstyled"
                    >
                      <li
                        v-for="cNav in nav.children"
                        :key="cNav.id"
                        class="iv2-nav__item theme__nav-item--inner"
                      >
                        <router-link
                          v-if="cNav.link_type == 'internal'"
                          @click.native="closeMenu"
                          :to="cNav.link_href + lang + query"
                          :target="cNav.target_type"
                          class="iv2-nav__link theme__nav-link--inner"
                          ><span
                            class="iv2-nav__link--text effect"
                            :class="menuAnimation"
                            >{{ cNav.item_name }}</span
                          ></router-link
                        >
                        <a
                          v-else-if="cNav.link_type == 'external'"
                          @click="closeMenu"
                          :href="cNav.link_href"
                          :target="cNav.target_type"
                          rel="noopener"
                          class="iv2-nav__link theme__nav-link--inner"
                          ><span
                            class="iv2-nav__link--text effect"
                            :class="menuAnimation"
                            >{{ cNav.item_name }}</span
                          ></a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <hr class="theme__nav--hr" />
              <div class="iv2-nav__footer">
                <div class="row">
                  <div class="col-md-8 pb-4 pb-md-0">
                    <div class="row">
                      <div
                        v-for="nav in extra"
                        :key="nav.id"
                        class="col-xl-6 mb-xl-4"
                      >
                        <router-link
                          v-if="nav.link_type == 'internal'"
                          @click.native="closeMenu"
                          :to="nav.link_href + lang + query"
                          :target="nav.target_type"
                          class="iv2-nav__extra-link theme__nav-link"
                          >{{ nav.item_name }}</router-link
                        >
                        <a
                          v-else-if="nav.link_type == 'external'"
                          @click="closeMenu"
                          :href="nav.link_href"
                          :target="nav.target_type"
                          rel="noopener"
                          class="iv2-nav__extra-link theme__nav-link"
                          >{{ nav.item_name }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <ul
                      class="iv2-nav__sns d-flex justify-content-end mb-0 list-unstyled"
                    >
                      <li
                        v-if="sns.is_linked_line == 1"
                        class="iv2-nav__sns-item theme__nav-sns"
                      >
                        <a
                          :href="sns.line_url"
                          class="iv2-nav__sns-link theme__nav-sns-link"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-line"></i>
                        </a>
                      </li>
                      <li
                        v-if="sns.is_linked_facebook == 1"
                        class="iv2-nav__sns-item theme__nav-sns"
                      >
                        <a
                          :href="sns.facebook_url"
                          class="iv2-nav__sns-link theme__nav-sns-link"
                          target="_blank"
                          rel="noopener"
                          style="font-size: 1.3rem"
                        >
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li
                        v-if="sns.is_linked_twitter == 1"
                        class="iv2-nav__sns-item theme__nav-sns"
                      >
                        <a
                          :href="sns.twitter_url"
                          class="iv2-nav__sns-link theme__nav-sns-link"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li
                        v-if="sns.is_linked_instagram == 1"
                        class="iv2-nav__sns-item theme__nav-sns"
                      >
                        <a
                          :href="sns.instagram_url"
                          class="iv2-nav__sns-link theme__nav-sns-link"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <!-- <li
                        v-if="rss.is_linked_blog == 1"
                        class="iv2-nav__sns-item theme__nav-sns"
                      >
                        <a
                          :href="rss.url"
                          class="iv2-nav__sns-link theme__nav-sns-link"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fas fa-rss"></i>
                        </a>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 pr-lg-5 p-0">
              <div v-if="has_news == 1" class="iv2-nav__category">
                <div style="word-break: keep-all">
                  <span
                    v-for="category in categories"
                    :key="category.id"
                    class="mr-3"
                  >
                    <router-link
                      :to="{
                        name: 'blog',
                        query: { category_id: category.id },
                      }"
                      @click.native="closeMenu"
                      class="iv2-nav__category--link theme__nav--category"
                    >
                      {{ category.name }} ({{ category.news_count }})
                    </router-link>
                  </span>
                </div>
                <hr class="theme__nav--hr" />
                <router-link
                  :to="{ name: 'blog' }"
                  class="iv2-nav__blog--link theme__nav-link"
                  @click.native="closeMenu"
                  >{{ $store.state.themeLang.general.nav_blog }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div
        v-if="activeSearch"
        class="iv2-nav__search theme__nav-bg position-fixed vh-100"
      >
        <div
          @click="closeMenu()"
          class="iv2-nav__search--close iv2-global__menu text-center theme__nav-bg"
        >
          <div class="iv2-nav__search--btn">
            <div class="iv2-nav__search--inline">
              <span
                class="iv2-nav__search--text theme__menu-text theme__nav-text"
              >
                {{ $store.state.themeLang.general.close }}
              </span>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 mb-5">
              <div class="text-center theme__heading mb-5">
                <h3
                  class="iv2-top-news__title theme__heading-text theme__nav-text"
                >
                  {{ $store.state.themeLang.general.search_title }}
                </h3>
                <p
                  class="iv2-top-news__subtitle theme__heading-subtext theme__nav-text"
                >
                  {{ $store.state.themeLang.general.search_subtitle }}
                </p>
              </div>

              <BaseSearch />
            </div>
            <div class="col-12">
              <BaseTagList />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </header>
</template>

<script>
import BaseSearch from "@/components/items/BaseSearch.vue";
import BaseTagList from "@/components/items/BaseTagList.vue";

export default {
  components: {
    BaseSearch,
    BaseTagList,
  },

  props: {
    global: Array,
    extra: Array,
    sns: Object,
    organization: Object,
    information: Object,
    rss: Object,
    count: Number,
  },

  data() {
    return {
      activeMenu: false,
      activeSearch: false,
      scrollY: 0,
      startScore: "",
      query: "",
      lang: "",
      environment: "",
      menuAnimation: "",
      categories: [],
      has_news: 0,
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path || to.query !== from.query) {
        this.closeMenu();
      }
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);

    const newsRepository = this.$repository.get("news");
    const categories = newsRepository.categories("blog");
    const self = this;
    categories.then((result) => {
      self.categories = result.data.response.categories;
      self.has_news = result.data.response.has_news;
    });
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },

    touchHandler() {
      this.activeMenu = !this.activeMenu;

      if (this.activeMenu == true) {
        const el = document.body;
        el.classList.add("noscroll");
        setTimeout(() => {
          this.menuAnimation = "display";
        }, 300);
      } else {
        const el = document.body;
        el.classList.remove("noscroll");
        this.menuAnimation = "";
      }
    },

    openSearch() {
      this.activeSearch = true;
      const el = document.body;
      el.classList.add("noscroll");
    },

    closeMenu() {
      this.activeMenu = false;
      this.activeSearch = false;
      this.menuAnimation = "";
      const el = document.body;
      el.classList.remove("noscroll");
    },
  },
};
</script>

<style lang="scss">
.iv2-global {
  &.scroll {
    -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  }

  .iv2-global__items {
    display: flex;

    .iv2-global__logo {
      width: 320px;
      margin: 10px 40px;

      .iv2-global__logo-block {
        position: relative;
        width: 100%;
        height: 80px;

        .iv2-global__logo-img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }

    .iv2-global__heading-text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      line-height: 1.5;
    }

    .iv2-global__search {
      margin-left: auto;
      width: 148px;
      cursor: pointer;
      position: relative;

      .iv2-global__search--block {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -45%);

        .iv2-global__search--icon {
          font-size: 1.5rem;
          color: #777;
        }

        .iv2-global__search--text {
          margin: 5px 0;
          line-height: 1;
          font-size: 12px;
          letter-spacing: 3px;
          font-weight: bold;
        }
      }

      &::before {
        content: "";
        height: 80%;
        border-left: 1px solid #bcbcbc;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .iv2-global__menu {
      padding: 10px 0;
      z-index: 111;
      width: 148px;
      transition: 0.5s;
      cursor: pointer;

      .iv2-global__menu-block {
        width: 100%;
        height: 80px;
        position: relative;

        .iv2-global__menu-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .iv2-global__drawer {
            font-size: 14px;
            font-weight: 600;
            display: block;
            white-space: nowrap;
            position: relative;

            &::before {
              content: "";
              height: 2px;
              width: 50%;
              position: absolute;
              bottom: -1px;
              left: 0;
            }

            &::after {
              content: "";
              height: 2px;
              width: 50%;
              position: absolute;
              bottom: -1px;
              right: 0;
            }
          }
        }

        &::before {
          content: "";
          height: 100%;
          border-left: 1px solid #fff;
          position: absolute;
          left: -1px;
        }
      }
    }
  }

  .iv2-nav {
    top: 0;
    left: 0;
    padding-top: 150px;
    width: 100vw;
    z-index: 1;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    .iv2-nav__category {
      .iv2-nav__category--link {
        &:hover {
          text-decoration: none;
        }
      }

      .iv2-nav__blog--link {
        text-decoration: underline;
      }
    }

    .iv2-nav__block {
      > .iv2-nav__list {
        vertical-align: top;

        .iv2-nav__item {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 30px;
          padding-right: 20px;

          .iv2-nav__link {
            display: inline-block;
            position: relative;

            .iv2-nav__link--text {
              &.effect {
                -webkit-transition: all 0.7s ease;
                transition: all 0.7s ease;
                -webkit-clip-path: inset(0 100% 0 0);
                clip-path: inset(0 100% 0 0);
                display: inline-block;
                line-height: 1.5;

                &.display {
                  -webkit-clip-path: inset(0);
                  clip-path: inset(0);
                  margin-bottom: 0;
                }
              }

              &.overlay {
                -webkit-transition: all 0.7s ease;
                transition: all 0.7s ease;
                display: inline-block;
                line-height: 1.5;
                opacity: 0.1;
                position: absolute;
                width: 100%;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }

              &::before {
                content: "-";
                margin-right: 7px;
              }
            }

            &::after {
              content: "";
              display: block;
              position: absolute;
              right: 0;
              bottom: 5px;
              left: 0;
              border-bottom: 2px solid;
              -webkit-transition: all 0.3s ease;
              transition: all 0.3s ease;
              -webkit-clip-path: inset(0 100% 0 0);
              clip-path: inset(0 100% 0 0);
            }

            &:hover {
              text-decoration: none;
              opacity: 1;

              &::after {
                -webkit-clip-path: inset(0);
                clip-path: inset(0);
              }
            }
          }

          > .iv2-nav__list {
            padding-left: 40px;

            .iv2-nav__item {
              font-size: 1rem;
              margin-bottom: 0;
              position: relative;

              &::before {
                content: "";
                position: absolute;
                display: block;
                height: 100%;
                left: -15px;
              }

              .iv2-nav__link {
                .iv2-nav__link--text {
                  &::before {
                    content: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .iv2-nav__footer {
      margin-top: 50px;
      padding-bottom: 60px;

      .iv2-nav__extra-link {
        font-size: 14px;
        text-decoration: underline;
      }

      .iv2-nav__sns-item {
        width: 30px;
        height: 30px;
        margin-left: 8px;
        border-radius: 50%;
        line-height: 1.8;

        .iv2-nav__sns-link {
          width: 100%;
          height: 100%;
          font-size: 1.1rem;
          padding: 0 6px;
        }
      }
    }
  }

  .iv2-nav__search {
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 111;
    padding-top: 100px;
    overflow-y: auto;
    overflow-x: hidden;

    .iv2-nav__search--close {
      position: fixed;
      top: 0;
      right: 0;
      width: 148px;
      height: 100px;
      cursor: pointer;

      .iv2-nav__search--btn {
        height: 100%;
        width: 100%;
        position: relative;

        .iv2-nav__search--inline {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .iv2-nav__search--text {
            font-size: 14px;
            font-weight: 600;
            display: block;
            white-space: nowrap;
            position: relative;

            &::before {
              content: "";
              height: 2px;
              width: 50%;
              position: absolute;
              bottom: -1px;
              left: 0;
            }

            &::after {
              content: "";
              height: 2px;
              width: 50%;
              position: absolute;
              bottom: -1px;
              right: 0;
            }
          }
        }

        &::before {
          content: "";
          height: 80%;
          border-left: 1px solid #fff;
          position: absolute;
          left: -1px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-nav {
    .iv2-nav__category {
      padding-top: 130px;
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-global {
    .iv2-nav {
      .iv2-nav__category {
        padding-top: 100px;
        padding-bottom: 50px;
      }

      .iv2-nav__block {
        > .iv2-nav__list {
          margin-bottom: 50px;

          .iv2-nav__item {
            font-size: 1.25rem;

            > .iv2-nav__list {
              .iv2-nav__item {
                font-size: 14px;
              }
            }
          }
        }
      }

      .iv2-nav__footer {
        padding-bottom: 0;
      }
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-global {
    .iv2-global__items {
      .iv2-global__logo {
        margin: 10px 20px;
      }

      .iv2-global__search {
        width: 123px;
      }

      .iv2-global__menu {
        width: 123px;

        .iv2-global__menu-block {
          .iv2-global__menu-btn {
            .iv2-global__drawer {
              font-size: 11px;
            }
          }
        }
      }
    }

    .iv2-nav {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .iv2-nav__search {
      .iv2-nav__search--close {
        width: 123px;

        .iv2-nav__search--btn {
          .iv2-nav__search--inline {
            .iv2-nav__search--text {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-global {
    .iv2-global__items {
      width: 100vw;

      .iv2-global__logo {
        width: 50%;
        margin: 5px 10px;

        .iv2-global__logo-block {
          height: 45px;
        }
      }

      .iv2-global__search {
        width: 67px;

        .iv2-global__search--block {
          .iv2-global__search--icon {
            font-size: 1rem;
          }

          .iv2-global__search--text {
            display: none;
          }
        }
      }

      .iv2-global__menu {
        width: 67px;
        padding: 5px 0;

        .iv2-global__menu-block {
          height: 45px;

          .iv2-global__menu-btn {
            .iv2-global__drawer {
              font-size: 10px;
            }
          }
        }
      }
    }

    .iv2-nav {
      padding-top: 50px;
      padding-bottom: 10rem;
      padding-bottom: calc(10rem + env(safe-area-inset-bottom));

      .iv2-nav__block {
        padding-right: 40px;
      }
    }

    .iv2-nav__search {
      .iv2-nav__search--close {
        width: 67px;
        height: 55px;

        .iv2-nav__search--btn {
          .iv2-nav__search--inline {
            .iv2-nav__search--text {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
