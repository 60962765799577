<template>
  <section class="theme__sub-bg">
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full">
          <div class="spacer height-40"></div>
        </div>

        <div class="column full text-center theme__heading" style="z-index: 1">
          <h3 class="iv2-top-news__title theme__heading-text">
            {{ $store.state.themeLang.general.search_title }}
          </h3>
          <p class="iv2-top-news__subtitle theme__heading-subtext">
            {{ $store.state.themeLang.general.search_subtitle }}
          </p>
        </div>

        <div class="column full">
          <div class="spacer height-40"></div>
        </div>

        <div class="column full">
          <BaseSearch />
        </div>

        <div class="column full">
          <div class="spacer height-40"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseSearch from "@/components/items/BaseSearch.vue";

export default {
  components: {
    BaseSearch,
  },

  props: {
    items: Object,
  },
};
</script>

<style lang="scss"></style>
